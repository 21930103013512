.ctct-form-wrapper .ctct-button {
  font-size: 0.9rem;
}

.ctct-form-wrapper .ctct-message {
  border: 1px solid;
  padding: 1em;
}

.ctct-form-wrapper .ctct-message.ctct-error {
  background-color: rgba(255, 65, 54, 0.02);
  border-color: #ff4136;
  color: #cf0b00;
}

.ctct-form-wrapper .ctct-message.ctct-success {
  background-color: rgba(46, 204, 64, 0.02);
  border-color: #2ecc40;
  color: #1b7926;
}

.ctct-form-wrapper .ctct-message .ctct-dismiss-ajax-notice {
  border-radius: 50%;
  float: right;
  line-height: 1;
  padding: 0.85rem 1rem;
  text-decoration: none;
}

.ctct-form-wrapper .ctct-form-field {
  margin: 0 0 1rem;
}

.ctct-form-wrapper .ctct-field-inline {
  display: inline-block;
}

@media (min-width: 992px) {
  .ctct-form-wrapper .ctct-field-half {
    float: left;
    margin-right: 2%;
    width: 48%;
  }
  .ctct-form-wrapper .ctct-field-half:last-of-type {
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  .ctct-form-wrapper .ctct-field-third {
    float: left;
    margin-right: 2%;
    width: 32%;
  }
  .ctct-form-wrapper .ctct-field-third:last-of-type {
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  .ctct-form-wrapper .ctct-field-fourth {
    float: left;
    margin-right: 2%;
    width: 24%;
  }
  .ctct-form-wrapper .ctct-field-fourth:last-of-type {
    margin-right: 0;
  }
}

.ctct-form-wrapper input[type='text']:required:valid, .ctct-form-wrapper input[type='email']:required:valid, .ctct-form-wrapper input[type='password']:required:valid, .ctct-form-wrapper input[type='tel']:required:valid, .ctct-form-wrapper input[type='number']:required:valid {
  background-image: url(../images/check_circle.svg);
  background-position: 8px 50%;
  background-repeat: no-repeat;
  background-size: 18px;
  border-color: #2ecc40;
  padding-left: 32px;
}

.ctct-form-wrapper input[type='text']:required.ctct-invalid, .ctct-form-wrapper input[type='text'].ctct-invalid, .ctct-form-wrapper input[type='email']:required.ctct-invalid, .ctct-form-wrapper input[type='email'].ctct-invalid, .ctct-form-wrapper input[type='password']:required.ctct-invalid, .ctct-form-wrapper input[type='password'].ctct-invalid, .ctct-form-wrapper input[type='tel']:required.ctct-invalid, .ctct-form-wrapper input[type='tel'].ctct-invalid, .ctct-form-wrapper input[type='number']:required.ctct-invalid, .ctct-form-wrapper input[type='number'].ctct-invalid {
  background: #fff url(../images/error.svg) no-repeat;
  background-color: rgba(255, 65, 54, 0.02);
  background-position: 8px 50%;
  background-size: 24px;
  border-color: #ff4136;
  padding-left: 40px;
}

.ctct-form-wrapper .ctct-field-error {
  font-size: 0.85rem;
  font-style: italic;
}

.ctct-form-wrapper input.ctct-invalid {
  background: #fff url(../images/error.svg) no-repeat;
  background-color: rgba(255, 65, 54, 0.02);
  background-position: 8px 50%;
  background-size: 24px;
  border-color: #ff4136;
  padding-left: 40px;
}

.ctct-form-wrapper input.ctct-label-left,
.ctct-form-wrapper textarea.ctct-label-left {
  display: inline-block;
  width: 75%;
}

.ctct-form-wrapper span.ctct-label-left {
  display: inline-block;
  margin-right: 5%;
  width: 20%;
}

.ctct-form-wrapper input.ctct-label-right,
.ctct-form-wrapper textarea.ctct-label-right {
  display: inline-block;
  margin-right: 5%;
  width: 75%;
}

.ctct-form-wrapper input.ctct-label-right[type="checkbox"],
.ctct-form-wrapper textarea.ctct-label-right[type="checkbox"] {
  width: auto;
}

.ctct-form-wrapper span.ctct-label-right {
  display: inline-block;
  width: 20%;
}

.ctct-form-wrapper span.ctct-label-hidden {
  left: -9999px !important;
  position: absolute !important;
  top: -9999px !important;
}

.ctct-form-wrapper .ctct_usage {
  border: 0 none;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.ctct-form-wrapper .no-recaptcha .ctct-submitted:disabled {
  background-image: url(../images/oval.min.svg);
  background-position: center;
  background-repeat: no-repeat;
  color: transparent;
  cursor: wait;
  opacity: 0.3;
}

.ctct-form-wrapper .has-recaptcha .ctct-submitted:disabled {
  cursor: not-allowed;
}

.ctct-form-wrapper .ctct-form::after {
  clear: both;
  content: '';
  display: table;
}

.ctct-form-wrapper .ctct-form .ctct-label-top label,
.ctct-form-wrapper .ctct-form .ctct-label-bottom label {
  display: block;
}

.ctct-form-wrapper .ctct-form .ctct-label-left label,
.ctct-form-wrapper .ctct-form .ctct-label-right label {
  display: inline-block;
}

.ctct-form-wrapper .ctct-form abbr {
  border: none;
  color: #ff4136;
  font-size: 0.9rem;
}

.ctct-form-wrapper .ctct-input-container label {
  color: #aaa;
  font-size: 0.8rem;
}

.ctct-form-wrapper .ctct-field-error {
  color: #ff4136;
}

.ctct-form-wrapper .ctct-submit {
  cursor: pointer;
}

.ctct-form-wrapper .ctct-form-field-checkbox fieldset {
  border: none;
  padding: 0;
}

.ctct-form-wrapper .ctct-form-field-checkbox fieldset legend {
  font-weight: normal;
  padding: 0;
}

.ctct-twentyfourteen .ctct-form-description ~ .ctct-button {
  display: inline-block;
  font-size: 1rem;
  margin-bottom: 25px;
}

.ctct-twentyfourteen .ctct-form-field input:not(.ctct-checkbox) {
  width: 100%;
}

.ctct-twentyfifteen .ctct-form-description ~ .ctct-button {
  display: inline-block;
  font-size: 1rem;
  margin-bottom: 25px;
}

.ctct-twentyfifteen .ctct-form-field input[type="tel"] {
  padding: 0.5278em;
  width: 100%;
}

.ctct-twentyfifteen .ctct-form-wrapper .ctct-input-container label {
  font-size: 75%;
}

.ctct-twentysixteen .ctct-form-description ~ .ctct-button {
  display: inline-block;
  margin-bottom: 25px;
}

.ctct-twentysixteen .ctct-form-wrapper span.ctct-label-right {
  width: 80%;
}

.ctct-twentyseventeen .ctct-form-description ~ .ctct-button {
  display: inline-block;
  margin-bottom: 25px;
}

.ctct-twentyseventeen .ctct-form-wrapper .ctct-input-container label {
  display: inline;
}

.ctct-twentyseventeen .ctct-form-wrapper span.ctct-label-right {
  width: 80%;
}

.ctct-twentynineteen .ctct-form-description ~ .ctct-button {
  margin-bottom: 25px;
}

.ctct-twentynineteen .ctct-address {
  margin-bottom: 1rem;
}

.ctct-twentynineteen .ctct-form-field input[type="text"],
.ctct-twentynineteen .ctct-form-field input[type="email"],
.ctct-twentynineteen .ctct-form-field input[type="url"],
.ctct-twentynineteen .ctct-form-field input[type="tel"] {
  width: 100%;
}

@media screen and (max-width: 414px) {
  .ctct-twentynineteen .ctct-form-wrapper span.ctct-label-right {
    width: 80%;
  }
}

.ctct-twentytwenty .ctct-form-description ~ .ctct-button {
  margin-bottom: 25px;
}

.ctct-twentytwenty .ctct-form-wrapper .ctct-form abbr {
  font-size: 1.6rem;
}

.ctct-twentytwenty .ctct-form-wrapper .ctct-input-container label {
  font-size: 1.6rem;
}

.ctct-twentytwentyone .ctct-button {
  padding: 0 !important;
}

.ctct-twentytwentyone .ctct-form-field input[type="text"],
.ctct-twentytwentyone .ctct-form-field input[type="email"],
.ctct-twentytwentyone .ctct-form-field input[type="url"],
.ctct-twentytwentyone .ctct-form-field input[type="tel"] {
  width: 100%;
}
